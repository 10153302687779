import { StoryBlokSiteFolder } from '../types'

/**
 * Get querystring parameters
 *
 * @param paramName
 * @returns The value for the given paramName
 */
export const getUrlParam = <T = string>(
  paramName: string,
  defaultValue: string | null = null
): T | string | null => {
  if (typeof window === 'undefined') {
    return null
  }

  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(paramName) || defaultValue
}

/* Get full query string if window defined */
export const getUrlParamsString = () => {
  if (typeof window === 'undefined') {
    return null
  }
  return window.location.search
}

/**
 * Get full site url
 *
 * @param path
 * @returns The full site url
 */
export const getFullSiteUrl = (path = ''): string => {
  const siteURL = process.env.GATSBY_SITE_URL

  return new URL(path, siteURL).toString()
}

export const getPagePathname = (
  returnFullPath: boolean
): string | null | undefined => {
  if (typeof window === 'undefined') {
    return null
  }
  const pathname = window.location.pathname

  if (returnFullPath) {
    return pathname
  }
  const pages = pathname.split('/')
  return pages.pop()
}

/* Add a leading slash if not present */
export const getPathnameLeadingSlash = (path: string) => {
  return path.startsWith('/') ? path : '/' + path
}

/**
 * Rewrite slug to exclude some storyblok prefixes, like landbased / corporate / hr prefixes.
 *
 * @param slug
 * @param storyblokFolder
 * @returns
 */
export const rewriteSlug = (
  slug: string,
  storyblokFolder?: StoryBlokSiteFolder
) => {
  let newSlug = ''

  // Remove /corporate, /hr, /landbased from the url
  if (storyblokFolder) {
    const storyblokFolderRegex = new RegExp(
      `^/?((de|en)/?)?${storyblokFolder}/`
    )
    newSlug = slug?.replace(storyblokFolderRegex, '$1')
  }

  // Remove /home from the url
  newSlug = newSlug?.replace(/^((de|en))?(\/)?home/, '$1')

  // Home needs to be a single slash
  if (newSlug === '') {
    newSlug = '/'
  }

  return newSlug
}
