import { useState, useCallback, useEffect, useMemo } from 'react'
import { LayoutContainer } from '@hc/shared/components/layout'
import { IconNames } from '@hc/shared/components/icon'
import {
  ScrollDirection,
  useScrollDirection
} from '@hc/shared/hooks/use-scroll-direction'
import { IHeaderProps } from '@hc/shared/domains/storyblok/mappers/map-header'
import { useIsLG } from '@hc/shared/hooks/use-media-query'

import { SITE_ROOT } from '@/const'
import { Navigation } from '@/components/navigation'

import { HeaderContext } from './HeaderContext'
import {
  StyledHeader,
  StyledHeaderInner,
  StyledLogoLink,
  StyledLogo,
  NavButton,
  NavButtonIcon,
  NavHeader,
  StyledOverlay,
  StyledLanguageSwitchHeader
} from './Header.style'

const dropdownMenu = {
  _uid: 'dropdown',
  label: 'CORPORATE',
  link: undefined,
  isLight: true,
  items: [
    {
      _uid: 'vestigingen',
      label: 'Vestigingen',
      link: {
        isExternal: true,
        href: 'https://vestigingen.hollandcasino.nl/'
      }
    },
    {
      _uid: 'speel_online',
      label: 'SPEEL ONLINE',
      link: {
        isExternal: true,
        href: 'https://www.hollandcasino.nl/en/casino'
      }
    }
  ]
}

export const Header = ({ navList }: IHeaderProps): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const [isActive, setIsActive] = useState(false)
  const [isFixed, setIsFixed] = useState(false)
  const scrollDirection = useScrollDirection()
  const isLG = useIsLG()

  const items = useMemo(() => [dropdownMenu, ...(navList || [])], [navList])

  const handleToggleClick = useCallback(() => {
    if (activeIndex !== null) {
      setActiveIndex(null)
    } else {
      setIsActive(!isActive)
    }
  }, [isActive, activeIndex])

  useEffect(() => {
    if (scrollDirection === ScrollDirection.UP) {
      setIsFixed(true)
    } else if (scrollDirection === ScrollDirection.DOWN) {
      setIsFixed(false)
    }
  }, [scrollDirection])

  return (
    <>
      <StyledOverlay isActive={isActive} onClick={handleToggleClick} />
      <HeaderContext.Provider
        value={{
          activeIndex,
          setActiveIndex
        }}
      >
        <StyledHeader isFixed={isFixed || isActive}>
          <StyledLogoLink link={{ href: SITE_ROOT }} aria-label="Home">
            <StyledLogo isActive={isActive || isFixed} name={IconNames.LOGO} />
          </StyledLogoLink>
          <LayoutContainer>
            <StyledHeaderInner isFixed={isFixed} isActive={isActive}>
              <NavButton
                aria-label="Open navigation"
                onClick={handleToggleClick}
                isActive={isActive}
              >
                <NavButtonIcon
                  name={isActive ? IconNames.CARET_LEFT : IconNames.MENU}
                  isActive={isActive || isFixed}
                />
              </NavButton>
              {(isActive || isFixed) && (
                <NavHeader>
                  {activeIndex === null ? (
                    <StyledLogo
                      isActive={isActive || isFixed}
                      name={IconNames.LOGO}
                    />
                  ) : (
                    items[activeIndex].label
                  )}
                </NavHeader>
              )}
              <Navigation
                items={items}
                isActive={isActive}
                isFixed={isFixed}
                handleToggleClick={() => setIsActive(false)}
              />
              {isLG && <StyledLanguageSwitchHeader isFixed={isFixed} />}
            </StyledHeaderInner>
          </LayoutContainer>
        </StyledHeader>
      </HeaderContext.Provider>
    </>
  )
}
