import { Link } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'

interface IAProps {
  label?: string
  subLabel?: string
}

export const A = styled(Link)<IAProps>`
  color: inherit;
`
