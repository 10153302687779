import { css } from 'styled-components'

import TeodorRegular from './teodor/Teodor-Regular.otf'
import TeodorMedium from './teodor/Teodor-Medium.otf'
import TeodorBold from './teodor/Teodor-Bold.otf'
import SuisseIntlLight from './suisse-intl/SuisseIntl-Light.otf'
import SuisseIntlLightItalic from './suisse-intl/SuisseIntl-LightItalic.otf'
import SuisseIntlRegular from './suisse-intl/SuisseIntl-Regular.otf'
import SuisseIntlRegularItalic from './suisse-intl/SuisseIntl-RegularItalic.otf'
import SuisseIntlMedium from './suisse-intl/SuisseIntl-Medium.otf'
import SuisseIntlMediumItalic from './suisse-intl/SuisseIntl-MediumItalic.otf'
import SuisseIntlBold from './suisse-intl/SuisseIntl-Bold.otf'
import SuisseIntlBoldItalic from './suisse-intl/SuisseIntl-BoldItalic.otf'
import SofiaProBlack from './sofia-pro/SofiaPro-Black.woff'
import SofiaProLight from './sofia-pro/SofiaPro-Light.woff'
import SofiaProLightItalic from './sofia-pro/SofiaPro-LightItalic.woff'
import SofiaProRegular from './sofia-pro/SofiaPro-Regular.woff'

export default css`
  @font-face {
    font-family: Teodor;
    src: url(${TeodorRegular}) format('opentype');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: Teodor;
    src: url(${TeodorMedium}) format('opentype');
    font-style: normal;
    font-weight: 500;
    font-display: swap;
  }

  @font-face {
    font-family: Teodor;
    src: url(${TeodorBold}) format('opentype');
    font-style: normal;
    font-weight: 600;
    font-display: swap;
  }

  @font-face {
    font-family: 'Suisse Intl';
    src: url(${SuisseIntlLight}) format('opentype');
    font-style: normal;
    font-weight: 300;
    font-display: swap;
  }

  @font-face {
    font-family: 'Suisse Intl';
    src: url(${SuisseIntlLightItalic}) format('opentype');
    font-style: italic;
    font-weight: 300;
    font-display: swap;
  }

  @font-face {
    font-family: 'Suisse Intl';
    src: url(${SuisseIntlRegular}) format('opentype');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: 'Suisse Intl';
    src: url(${SuisseIntlRegularItalic}) format('opentype');
    font-style: italic;
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: 'Suisse Intl';
    src: url(${SuisseIntlMedium}) format('opentype');
    font-style: normal;
    font-weight: 600;
    font-display: swap;
  }

  @font-face {
    font-family: 'Suisse Intl';
    src: url(${SuisseIntlMediumItalic}) format('opentype');
    font-style: italic;
    font-weight: 600;
    font-display: swap;
  }

  @font-face {
    font-family: 'Suisse Intl';
    src: url(${SuisseIntlBold}) format('opentype');
    font-style: normal;
    font-weight: 700;
    font-display: swap;
  }

  @font-face {
    font-family: 'Suisse Intl';
    src: url(${SuisseIntlBoldItalic}) format('opentype');
    font-style: italic;
    font-weight: 700;
    font-display: swap;
  }

  @font-face {
    font-family: 'Sofia Pro';
    src: url(${SofiaProLight}) format('woff');
    font-style: normal;
    font-weight: 300;
    font-display: swap;
  }

  @font-face {
    font-family: 'Sofia Pro';
    src: url(${SofiaProLightItalic}) format('woff');
    font-style: italic;
    font-weight: 300;
    font-display: swap;
  }

  @font-face {
    font-family: 'Sofia Pro';
    src: url(${SofiaProRegular}) format('woff');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: 'Sofia Pro';
    src: url(${SofiaProBlack}) format('woff');
    font-style: normal;
    font-weight: 900;
    font-display: swap;
  }
`
