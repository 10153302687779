import React from 'react'
import { DefaultTheme, ThemeProvider } from 'styled-components'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import type {
  GatsbyBrowser,
  RouteUpdateArgs,
  WrapRootElementBrowserArgs
} from 'gatsby'

import { HCStorage } from './src/utils/storage'
import { SharedGlobalStyle } from './src/theme'
import { STORAGE_PREV_PAGE } from './src/const'

export const shouldStorePrevPage = (
  prevPageIgnoreList: string[],
  url: string
) => {
  const regex = new RegExp(`^(/en|/de)?(${prevPageIgnoreList.join('|')})/?$`)
  return url && !regex.test(url)
}

const onRouteUpdate = (
  { location }: RouteUpdateArgs,
  prevPageIgnoreList: string[] = []
) => {
  if (
    location?.pathname &&
    shouldStorePrevPage(prevPageIgnoreList, location.pathname)
  ) {
    HCStorage.setItem(STORAGE_PREV_PAGE, location.pathname)
  }
}

type WrapPageElementParams = Parameters<
  NonNullable<GatsbyBrowser['wrapPageElement']>
>

export const createWrapPageElement = (
  { element }: WrapPageElementParams[0],
  AppLayout: React.FC
) =>
  React.cloneElement(
    element, // I18nextProvider
    element.props,
    React.cloneElement(
      element.props.children, // I18nextContext.Provider
      element.props.children.props,
      React.createElement(
        AppLayout,
        undefined,
        element.props.children.props.children
      )
    )
  )

const queryClient = new QueryClient()

export const createWrapRootElement = (
  { element }: WrapRootElementBrowserArgs,
  THEME: DefaultTheme
) => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={THEME}>
      <SharedGlobalStyle />
      {element}
    </ThemeProvider>
  </QueryClientProvider>
)

export const SharedGatsbyBrowser = {
  onRouteUpdate,
  createWrapPageElement,
  createWrapRootElement
}
