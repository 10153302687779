module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-fix-fouc@1.0.1_gatsby@5.2.0/node_modules/gatsby-plugin-fix-fouc/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-styled-components@6.2.0_ixlgvrpzf7mze2aagjzkpzvkpm/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-react-i18next@3.0.1_ow3iypvvlkvdsq7llfropizeqq/node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["nl","en"],"defaultLanguage":"nl","redirect":false,"siteUrl":"https://corporate.hollandcasino.nl","trailingSlash":"always","i18nextOptions":{"defaultNS":"common","lowerCaseLng":true,"interpolation":{"escapeValue":false},"nsSeparator":false}},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-google-tagmanager@5.2.0_mmfbi2fzwqkczhgnjxxufhvoz4/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5KNTJRD","includeInDevelopment":true,"routeChangeEventName":"page-change-event","defaultDataLayer":null,"enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@5.2.0_lb6du3saekb5anf2gjv3wxj3oq/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
