import styled, { css } from 'styled-components'

import { Paragraph, Heading } from '../typography'
import { Link } from '../link'
import { Image } from '../image'
import { Icon } from '../icon'
import { LayoutRow } from '../layout'
import { Accordion } from '../accordion'
import { AccordionItem } from '../accordion-item'
import { ParagraphTypes } from '../../types/typography'
import { StyledLangSwitch } from '../language-switch/LanguageSwitch.styles'
import { Corner } from '../corner'

interface IProps {
  isCompact?: boolean
}

export const StyledFooter = styled.footer`
  position: relative;
  background-color: ${({ theme }) => theme.COMPONENTS.FOOTER_BACKGROUND};
  padding: 10.4rem 0 3.2rem;
  overflow: hidden;
  max-width: ${({ theme }) => theme.LAYOUT.LAYOUT_MAX_CONTAINER_WIDTH}px;
  margin: 0 auto;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    padding: 11.2rem 0 1.2rem;
  }
`

export const StyledRow = styled(LayoutRow)`
  row-gap: 6.4rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    flex-direction: row-reverse;
  }
`

export const StyledAccordion = styled(Accordion)`
  position: relative;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    display: flex;
    flex-wrap: wrap;
  }
`

export const StyledAccordionItem = styled(AccordionItem)<IProps>`
  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    width: 50%;

    ${({ isCompact }) =>
      isCompact &&
      css`
        width: 20%;
      `};
  }
`

export const StyledItem = styled.li`
  margin-top: 1.6rem;
`

export const StyledLinkListLink = styled(Link)`
  ${({ theme }) => theme.TYPOGRAPHY[ParagraphTypes.TEXT_M]};
  color: ${({ theme }) => theme.COLOR.BLUE_10};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledIcon = styled(Icon)`
  fill: ${({ theme }) => theme.COLOR.BLUE_50};
  color: ${({ theme }) => theme.COLOR.BLUE_50};

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    display: none;
  }
`

export const StyledBottom = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin-top: 4.8rem;
  min-height: 7.2rem;

  @media screen and (max-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
    flex-direction: row;
    flex-wrap: wrap;
    flex-basis: 100%;

    ${StyledLangSwitch} {
      order: -1;
    }
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    flex-direction: row-reverse;
    margin-top: 13.4rem;
  }
`

export const StyledDownloadTitle = styled(Heading)`
  color: ${({ theme }) => theme.COMPONENTS.FOOTER_TITLE_COLOR};
  text-align: center;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    text-align: left;
  }
`

export const StyledDownloadDescription = styled(Paragraph)`
  color: ${({ theme }) => theme.COLOR.BLUE_10};
  text-align: center;
  margin-top: 1.6rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    text-align: left;
  }
`

export const StyledDownloadList = styled.ul`
  display: flex;
  justify-content: center;
  gap: 2.4rem;
  margin-top: 3.2rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    justify-content: flex-start;
  }
`

export const StyledBottomList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.6rem 3.2rem;
  margin: 6.4rem 0 3.2rem;

  @media screen and (max-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
    flex-basis: 100%;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    margin: 0;
  }
`

export const StyledBottomListLink = styled(Link)`
  ${({ theme }) => theme.TYPOGRAPHY[ParagraphTypes.TEXT_XS]};
  color: ${({ theme }) => theme.COLOR.BLUE_40};
`

export const StyledCopyRight = styled(Paragraph)`
  color: ${({ theme }) => theme.COLOR.BLUE_40};
`

export const StyledDownloadImage = styled(Image)`
  height: 3.2rem;
  width: auto;
`

export const StyledImageList = styled.ul`
  display: flex;
  gap: 3.2rem;

  @media screen and (max-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
    flex-basis: 100%;
    justify-content: center;
  }
`

export const StyledImage = styled(Image)`
  height: 7.2rem;
  width: auto;
`

export const StyledSocialListWrapper = styled.div`
  @media screen and (max-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
    width: 100%;
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
  }
`

export const StyledCorner = styled(Corner)`
  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    transform: translateX(6.4rem);
  }
`
