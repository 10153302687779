import { css } from 'styled-components'

import { HeadingTypes, ParagraphTypes } from '../../types/typography'

const DISPLAY = css`
  font-family: ${({ theme }) => theme.FONT.PRIMARY};
  font-weight: 800;
  font-style: normal;
  line-height: 1.2;
`

const DISPLAY_XXS = css`
  ${DISPLAY};
  font-size: ${({ theme }) => theme.FONT_SIZE.DISPLAY_XXS}rem;
`

const DISPLAY_XS = css`
  ${DISPLAY};
  font-size: ${({ theme }) => theme.FONT_SIZE.DISPLAY_XXS}rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    font-size: ${({ theme }) => theme.FONT_SIZE.DISPLAY_XS}rem;
  }
`

const DISPLAY_S = css`
  ${DISPLAY};
  font-size: ${({ theme }) => theme.FONT_SIZE.DISPLAY_XS}rem;
  text-transform: ${({ theme }) => theme.COMPONENTS.HEADING_TEXT_TRANSFORM};

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    font-size: ${({ theme }) => theme.FONT_SIZE.DISPLAY_S}rem;
  }
`

const DISPLAY_M = css`
  ${DISPLAY};
  font-size: ${({ theme }) => theme.FONT_SIZE.DISPLAY_S}rem;
  text-transform: ${({ theme }) => theme.COMPONENTS.HEADING_TEXT_TRANSFORM};

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    font-size: ${({ theme }) => theme.FONT_SIZE.DISPLAY_M}rem;
  }
`

const DISPLAY_L = css`
  ${DISPLAY};
  font-size: ${({ theme }) => theme.FONT_SIZE.DISPLAY_S}rem;
  text-transform: ${({ theme }) => theme.COMPONENTS.HEADING_TEXT_TRANSFORM};

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    font-size: ${({ theme }) => theme.FONT_SIZE.DISPLAY_L}rem;
  }
`

const DISPLAY_XL = css`
  ${DISPLAY};
  font-size: ${({ theme }) => theme.FONT_SIZE.DISPLAY_S}rem;
  text-transform: ${({ theme }) => theme.COMPONENTS.HEADING_TEXT_TRANSFORM};

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    font-size: ${({ theme }) => theme.FONT_SIZE.DISPLAY_XL}rem;
  }
`

const DISPLAY_XXL = css`
  ${DISPLAY};
  font-size: ${({ theme }) => theme.FONT_SIZE.DISPLAY_S}rem;
  text-transform: ${({ theme }) => theme.COMPONENTS.HEADING_TEXT_TRANSFORM};

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    font-size: ${({ theme }) => theme.FONT_SIZE.DISPLAY_XXL}rem;
  }
`

const TEXT = css`
  font-family: ${({ theme }) => theme.FONT.PRIMARY};
  font-weight: 400;
  font-style: normal;
  line-height: 1.4;
`

const TEXT_XS = css`
  ${TEXT};
  font-size: ${({ theme }) => theme.FONT_SIZE.TEXT_XS}rem;
`

const TEXT_S = css`
  ${TEXT};
  font-size: ${({ theme }) => theme.FONT_SIZE.TEXT_S}rem;
`

const TEXT_M = css`
  ${TEXT};
  font-size: ${({ theme }) => theme.FONT_SIZE.TEXT_M}rem;
`

const TEXT_L = css`
  ${TEXT};
  font-size: ${({ theme }) => theme.FONT_SIZE.TEXT_M}rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    font-size: ${({ theme }) => theme.FONT_SIZE.TEXT_L}rem;
  }
`

export const TYPOGRAPHY = {
  [HeadingTypes.DISPLAY_XXL]: DISPLAY_XXL,
  [HeadingTypes.DISPLAY_XL]: DISPLAY_XL,
  [HeadingTypes.DISPLAY_L]: DISPLAY_L,
  [HeadingTypes.DISPLAY_M]: DISPLAY_M,
  [HeadingTypes.DISPLAY_S]: DISPLAY_S,
  [HeadingTypes.DISPLAY_XS]: DISPLAY_XS,
  [HeadingTypes.DISPLAY_XXS]: DISPLAY_XXS,
  [ParagraphTypes.TEXT_L]: TEXT_L,
  [ParagraphTypes.TEXT_M]: TEXT_M,
  [ParagraphTypes.TEXT_S]: TEXT_S,
  [ParagraphTypes.TEXT_XS]: TEXT_XS
}
