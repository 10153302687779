import { IconNames } from '../constants'
import { SvgComponent } from '../Icon.types'

type IconType = {
  [i in IconNames]: SvgComponent
}

/* eslint-disable @typescript-eslint/no-var-requires */
export const Icons: IconType = {
  [IconNames.CARET_DOWN]: require('./caret-down.svg').default,
  [IconNames.CARET_LEFT]: require('./caret-left.svg').default,
  [IconNames.CARET_RIGHT]: require('./caret-right.svg').default,
  [IconNames.CARET_UP]: require('./caret-up.svg').default,
  [IconNames.CHECK]: require('./check.svg').default,
  [IconNames.CART]: require('./cart.svg').default,
  [IconNames.CHIP]: require('./chip.svg').default,
  [IconNames.CLOVER]: require('./clover.svg').default,
  [IconNames.CROSS]: require('./cross.svg').default,
  [IconNames.DOWNLOAD]: require('./download.svg').default,
  [IconNames.FACEBOOK]: require('./facebook.svg').default,
  [IconNames.FILTER]: require('./filter.svg').default,
  [IconNames.FILTER_LINE]: require('./filter-line.svg').default,
  [IconNames.INFO]: require('./info.svg').default,
  [IconNames.INFO_OUTLINE]: require('./info-outline.svg').default,
  [IconNames.INSTAGRAM]: require('./instagram.svg').default,
  [IconNames.LINK]: require('./link.svg').default,
  [IconNames.LINKEDIN]: require('./linkedin.svg').default,
  [IconNames.LOADER]: require('./loader.svg').default,
  [IconNames.LOGO]: require('./logo.svg').default,
  [IconNames.MENU]: require('./menu.svg').default,
  [IconNames.MINUS]: require('./minus.svg').default,
  [IconNames.PLUS]: require('./plus.svg').default,
  [IconNames.SEARCH]: require('./search.svg').default,
  [IconNames.TWITTER]: require('./twitter.svg').default,
  [IconNames.USER]: require('./user.svg').default,
  [IconNames.YOUTUBE]: require('./youtube.svg').default,
  [IconNames.PLAY]: require('./play.svg').default
}
