export const FONT_SIZE = {
  DISPLAY_XXS: 1.6,
  DISPLAY_XS: 2,
  DISPLAY_S: 3.2,
  DISPLAY_M: 4.8,
  DISPLAY_L: 6.4,
  DISPLAY_XL: 8,
  DISPLAY_XXL: 12,
  TEXT_XS: 1.2,
  TEXT_S: 1.4,
  TEXT_M: 1.6,
  TEXT_L: 2
}
