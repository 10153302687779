export const COLOR = {
  BACKGROUND: '#F7F7F7',
  LIGHT_BLUE: '#00AEEF',
  LIGHT_BLUE_HOVER: '#0088BB',
  DARK_BLUE: '#021D37',
  DARK_BLUE_30: '#CCD2D7',
  PINK: '#F9258B',
  GOLD: '#CA9630',
  NEUTRAL_10: '#FFFFFF',
  NEUTRAL_100: '#000000'
}
