import { Locale, LocaleUpper } from '../../utils/types'
import { SiteUtils } from '../../utils/site'
import { getPagePathname, getPathnameLeadingSlash } from '../../utils/url'

export const SUPPORTED_LANGUAGES = SiteUtils.select({
  corporate: ['nl', 'en'],
  landbased: ['nl', 'de', 'en'],
  hr: ['nl']
}) || ['nl', 'de', 'en']

export const stringToLanguageUpper = (lang: string | null): LocaleUpper => {
  return stringToLanguage(lang).toUpperCase() as LocaleUpper
}

export const stringToLanguage = (lang: string | null): Locale => {
  if (lang === 'default') {
    return 'nl'
  }

  if (!lang || !SUPPORTED_LANGUAGES.includes(lang)) {
    throw new Error(`Unsupported language: ${lang}`)
  }

  return lang as Locale
}

export const getCurrentLanguagePath = (lang: string) => {
  return lang === 'nl' ? '' : `/${lang}`
}

export const getPathWithoutLanguage = (lang: string, path?: string) => {
  if (typeof window === 'undefined') {
    return null
  }
  const langPath = getCurrentLanguagePath(lang)
  const pathName = path || getPagePathname(true) || '/'

  if (langPath && pathName.startsWith(langPath)) {
    return pathName.replace(langPath, '')
  }
  return pathName
}

export const getFullTranslatedStringFromLocale = (lang: string) => {
  let fullLanguage = ''
  switch (lang) {
    case 'nl':
      fullLanguage = 'languages.nl'
      break
    case 'en':
      fullLanguage = 'languages.en'
      break
    case 'de':
      fullLanguage = 'languages.de'
      break
  }
  return fullLanguage
}

export const getLocalisedPath = (lang: string, path: string) => {
  return getPathnameLeadingSlash(getCurrentLanguagePath(lang) + path)
}
