import { ICardProps } from '../../../components/card'
import { IHrefLink } from '../../../utils/types'
import { FetchedHeaderNavItem } from '../models/components/types'
import mapCallToAction, { ICallToAction } from '../mappers/map-call-to-action'

import mapCard from './map-card'
import mapLink from './map-link'

export interface IHeaderNavItem {
  _uid: string
  label?: string
  items?: ICallToAction[] | undefined
  link?: IHrefLink | null
  cardList?: ICardProps[] | undefined
  isLight?: boolean
}

const mapHeaderNavItem = ({
  items,
  link,
  cardList,
  ...props
}: FetchedHeaderNavItem): IHeaderNavItem => {
  return {
    items: items ? items.map(item => mapCallToAction(item)) : [],
    link: link && mapLink(link),
    cardList: cardList ? cardList.map(card => mapCard(card)) : [],
    ...props
  }
}

export default mapHeaderNavItem
