import { CorporateIconNames } from './constants'
import { StyledIcon } from './Icon.styles'
import { Icons } from './svg'

export interface ICorporateIconProps {
  name: CorporateIconNames
  size?: string
  className?: string
}

export const CorporateIcon: React.FC<ICorporateIconProps> = ({
  name,
  className
}) => {
  const SvgIcon = Icons[name]
  if (SvgIcon) {
    return (
      <StyledIcon className={className}>
        <SvgIcon />
      </StyledIcon>
    )
  }

  return null
}
