import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

import { Icon } from '../icon'

import type { AccordionVariant } from './AccordionItem.types'

interface IProps {
  isActive?: boolean
  variant?: AccordionVariant
}

export const StyledAccordion = styled.div<IProps>`
  width: 100%;

  ${({ isActive, variant }) =>
    isActive &&
    variant === 'dark' &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.COLOR.BLUE_100};

      &:last-of-type {
        border: none;
      }
    `};

  ${({ isActive, variant }) =>
    isActive &&
    variant === 'compact' &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.COLOR.BLUE_10};

      &:last-of-type {
        border: none;
      }
    `};

  ${({ isActive, variant }) =>
    isActive &&
    variant === 'extraCompact' &&
    css`
      &:last-of-type {
        border: none;
      }
    `};

  ${({ isActive, variant }) =>
    isActive &&
    variant === 'light' &&
    css`
      margin-bottom: 0.8rem;

      &:last-of-type {
        margin-bottom: 0;
      }

      @media screen and (min-width: ${({ theme }) =>
          theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
        margin-bottom: 1.6rem;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    `};
`

export const StyledAccordionButton = styled.button<IProps>`
  display: flex;
  width: 100%;
  cursor: default;
  text-align: left;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  border: none;
  outline: none;
  color: ${({ theme }) => theme.COMPONENTS.ACCORDION_TITLE_COLOR};
  padding: 2.4rem 0;

  ${({ variant }) =>
    variant === 'light' &&
    css`
      color: ${({ theme }) => theme.COLOR.BLUE_90};
      padding: 1.6rem;
      border: 1px solid ${({ theme }) => theme.COLOR.BLUE_10};
      border-radius: ${({ theme }) => theme.BORDER_RADIUS.S}rem;
      width: calc(100% + 3.2rem);
      margin-left: -1.6rem;
    `};

  ${({ variant }) =>
    (variant === 'compact' || variant === 'extraCompact') &&
    css`
      color: ${({ theme }) => theme.COLOR.BLUE_90};
    `};

  ${({ variant }) =>
    variant === 'extraCompact' &&
    css`
      padding: 0;
      margin: 0;
    `};

  ${({ isActive }) =>
    isActive &&
    css`
      &:hover {
        cursor: pointer;
      }
    `};
`

export const StyledAccordionIcon = styled(Icon)<IProps>`
  display: flex;
  fill: ${({ theme }) => theme.COMPONENTS.ACCORDION_ICON_COLOR};
  color: ${({ theme }) => theme.COMPONENTS.ACCORDION_ICON_COLOR};

  ${({ variant }) =>
    variant === 'light' &&
    css`
      height: 3.2rem;
      padding: 1rem;
    `};

  ${({ variant }) =>
    variant === 'compact' &&
    css`
      fill: ${({ theme }) => theme.COLOR.BLUE_10};
      color: ${({ theme }) => theme.COLOR.BLUE_10};
    `};

  ${({ variant }) =>
    variant === 'extraCompact' &&
    css`
      background-color: ${({ theme }) => theme.COLOR.NEUTRAL_10};
      fill: ${({ theme }) => theme.COLOR.BLUE_30};
      color: ${({ theme }) => theme.COLOR.BLUE_30};
    `};
`

export const StyledAccordionBody = styled(motion.div)`
  overflow: hidden;
`

export const StyledAccordionContainer = styled.div<IProps>`
  padding-bottom: 4.8rem;

  ${({ variant }) =>
    variant === 'light' &&
    css`
      padding: 4rem 0 2.4rem;

      @media screen and (min-width: ${({ theme }) =>
          theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
        padding: 6.4rem 0 4rem;
      }
    `};

  ${({ variant }) =>
    variant === 'extraCompact' &&
    css`
      padding-bottom: 0;
    `};
`
