export enum IconNames {
  CARET_DOWN = 'caret-down',
  CARET_LEFT = 'caret-left',
  CARET_RIGHT = 'caret-right',
  CARET_UP = 'caret-up',
  CART = 'cart',
  CHECK = 'check',
  CHIP = 'chip',
  CLOVER = 'clover',
  CROSS = 'cross',
  DOWNLOAD = 'download',
  FACEBOOK = 'facebook',
  FILTER = 'filter',
  FILTER_LINE = 'filter-line',
  INFO = 'info',
  INFO_OUTLINE = 'info-outline',
  INSTAGRAM = 'instagram',
  LINK = 'link',
  LINKEDIN = 'linkedin',
  LOADER = 'loader',
  LOGO = 'logo',
  MENU = 'menu',
  MINUS = 'minus',
  PLUS = 'plus',
  SEARCH = 'search',
  TWITTER = 'twitter',
  USER = 'user',
  YOUTUBE = 'youtube',
  PLAY = 'play'
}
