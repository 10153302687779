import { CSSProp } from 'styled-components'

import { IImageProps } from '../../../components/image'
import { FetchedFooter } from '../models/components/types'
import { mapStaticImage } from '../mappers/map-image'
import { ImageField } from '../models/fields/types'

import mapSocialLink, { ISocialLink } from './map-social-link'
import mapFooterNavList, { IFooterNavList } from './map-footer-nav-list'
import { ICallToAction } from './map-call-to-action'
import mapDownloadButton, { IDownloadButtonProps } from './map-download-button'

export interface IFooterProps {
  navLists?: IFooterNavList[]
  socialList?: ISocialLink[]
  bottomList?: ICallToAction[]
  bottomImageList?: IImageProps[]
  copyright?: string
  downloadButtons?: IDownloadButtonProps[]
  downloadTitle?: string
  downloadDescription?: string
  footerStyle?: CSSProp
}

export default function mapFooter({
  navLists,
  socialList,
  bottomImageList,
  downloadButtons,
  ...props
}: FetchedFooter): IFooterProps {
  return {
    navLists: navLists
      ? navLists?.map(navList => mapFooterNavList(navList))
      : [],
    socialList: socialList ? socialList.map(item => mapSocialLink(item)) : [],
    bottomImageList: bottomImageList
      ? bottomImageList.map((image: ImageField) => mapStaticImage(image))
      : [],
    downloadButtons: downloadButtons
      ? downloadButtons.map(downloadButton => mapDownloadButton(downloadButton))
      : [],
    ...props
  }
}
