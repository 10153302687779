import { useTranslation } from 'gatsby-plugin-react-i18next'
import { stringToLanguage, getLocalisedPath } from '@hc/shared/i18n/locales'

export const useHCTranslation = () => {
  const translation = useTranslation()
  const currentLanguage = stringToLanguage(translation.i18n.language)
  const toLocalisedPath = (path: string) => {
    return getLocalisedPath(translation.i18n.language, path)
  }
  return {
    ...translation,
    currentLanguage,
    toLocalisedPath
  }
}
