import styled, { css, keyframes } from 'styled-components'
import { LayoutColumn } from '@hc/shared/components/layout'
import { Icon } from '@hc/shared/components/icon'
import { Link } from '@hc/shared/components/link'
import { HeadingTypes } from '@hc/shared/types/typography'

interface StyledNavSubProps {
  isActive?: boolean
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const StyledNavSub = styled.div<StyledNavSubProps>`
  @media screen and (max-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
    transition: 0.3s ${({ theme }) => theme.TRANSITION.TRANSITION_EASE_IN_OUT};
    transform: translateX(100vw);
    position: fixed;
    background-color: ${({ theme }) => theme.COLOR.NEUTRAL_10};
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    position: absolute;
    top: 2.5em;
    width: 100%;
    width: max-content;
    animation: ${fadeIn} 0.4s;
    z-index: -1;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      @media screen and (max-width: ${({ theme }) =>
          theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
        transform: translateX(0);
      }

      @media screen and (min-width: ${({ theme }) =>
          theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
        opacity: 1;
      }
    `};
`

export const StyledButton = styled.button`
  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    display: none;
  }
`

export const StyledIcon = styled(Icon)`
  margin-left: 1.4rem;
`
export const StyledContainer = styled.div`
  @media screen and (max-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
    height: 100%;
    overflow-y: auto;
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    background-color: ${({ theme }) => theme.COLOR.NEUTRAL_10};
    border-radius: ${({ theme }) => theme.BORDER_RADIUS.S}rem;
    overflow: hidden;
  }
`

interface IStyledListProps {
  itemsPerRow?: number
}

export const StyledList = styled.ul<IStyledListProps>`
  @media screen and (max-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
    overflow: auto;
    flex-grow: 1;
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    display: flex;
    flex-direction: column;
    gap: 0.625em;
  }
`

export const StyledItem = styled.li`
  @media screen and (max-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
    background-color: ${({ theme }) => theme.COLOR.NEUTRAL_20};
    margin-bottom: 0.6rem;
  }
`

export const StyledLink = styled(Link)`
  ${({ theme }) => theme.TYPOGRAPHY[HeadingTypes.DISPLAY_XXS]};
  padding: 2.8rem;
  color: ${({ theme }) => theme.COLOR.BLUE_90};
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    width: ${({ link }) => (link?.isExternal ? '1.8rem' : '1.1rem')};
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    font-weight: 400;
    padding: 0.78em 1em;
    background-color: ${({ theme }) => theme.COLOR.NEUTRAL_10};

    svg {
      width: ${({ link }) => (link?.isExternal ? '1.5rem' : '0.8rem')};
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.COLOR.NEUTRAL_20};
    font-weight: 500;
  }
`

export const StyledCardsColumn = styled(LayoutColumn)`
  @media screen and (max-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
    display: none;
  }
`
