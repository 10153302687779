import { useContext, FC } from 'react'
import { IHeaderNavItem } from '@hc/shared/domains/storyblok/mappers/map-header-nav-item'
import { Link } from '@hc/shared/components/link'
import { IconNames } from '@hc/shared/components/icon/constants'
import { LanguageSwitch } from '@hc/shared/components/language-switch'
import { useIsLG } from '@hc/shared/hooks/use-media-query'

import { HeaderContext } from '../header/HeaderContext'

import {
  StyledContainer,
  StyledNav,
  StyledNavList,
  StyledNavItem,
  StyledNavButton,
  StyledIcon,
  LanguageSwitchWrapper
} from './Navigation.style'
import { NavigationSub } from './NavigationSub'

export interface IProps {
  items: IHeaderNavItem[]
  handleToggleClick: () => void
  [key: string]: unknown
}

export const Navigation: FC<IProps> = ({
  items,
  handleToggleClick,
  ...props
}) => {
  const { activeIndex, setActiveIndex } = useContext(HeaderContext)
  const isLG = useIsLG()

  return (
    <StyledContainer>
      <StyledNav {...props}>
        <StyledNavList>
          {items.map((item, idx) => (
            <StyledNavItem
              key={item.label}
              onMouseOver={() => setActiveIndex(idx)}
              onMouseOut={() => setActiveIndex(null)}
            >
              <StyledNavButton
                isActiveIndex={activeIndex === idx}
                light={item.isLight}
                as={item.items?.length ? 'button' : Link}
                link={item.link && item.link}
                onClick={!item.items?.length ? handleToggleClick : undefined}
                {...props}
              >
                {item.label?.toUpperCase()}
                {item.items?.length !== 0 && (
                  <StyledIcon
                    isCaret
                    name={IconNames.CARET_DOWN}
                    isActiveIndex={activeIndex === idx}
                    {...props}
                  />
                )}
                <StyledIcon isArrow name={IconNames.CARET_RIGHT} {...props} />
              </StyledNavButton>
              {item.items?.length !== 0 && activeIndex === idx && (
                <NavigationSub
                  items={item.items}
                  isActive={activeIndex === idx}
                  onClick={() => {
                    setActiveIndex(null)
                    handleToggleClick()
                  }}
                />
              )}
            </StyledNavItem>
          ))}
        </StyledNavList>
        {!isLG && (
          <LanguageSwitchWrapper>
            <LanguageSwitch />
          </LanguageSwitchWrapper>
        )}
      </StyledNav>
    </StyledContainer>
  )
}
