import styled from 'styled-components'

import { Icon, IIconProps } from '../icon'

export const SocialListContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 2.4rem;
`

export const SocialIcon = styled(Icon)<IIconProps>`
  fill: ${({ theme }) => theme.COLOR.BLUE_10};
  color: ${({ theme }) => theme.COLOR.BLUE_10};
`
