import styled, { css } from 'styled-components'

interface ISpanProps {
  size: string
  color?: string
  hasCircle?: boolean
  theme: { COLOR: { COLOR_BLACK: string } }
}

export const StyledIcon = styled.div<ISpanProps>(
  ({
    theme: { COLOR },
    color = COLOR.COLOR_BLACK,
    size = '2.4rem',
    hasCircle
  }) => css`
    display: inline-block;
    height: ${size};
    fill: ${color};
    line-height: 1;

    ${hasCircle &&
    css`
      border: 1px solid currentColor;
      border-radius: 50%;
      padding: 0.6rem;
      display: inline-flex;
    `}
    ${hasCircle &&
    color &&
    css`
      border-color: ${color};
    `}
    svg {
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }
  `
)
