import { COLOR } from '../basic/color'

export const COMPONENTS = {
  BODY_COLOR: COLOR.BLUE_90,
  HEADING_TEXT_TRANSFORM: 'unset',
  BUTTON_PRIMARY_BACKGROUND: COLOR.GOLD_70_PRIMARY,
  BUTTON_PRIMARY_COLOR: COLOR.GOLD_10,
  BUTTON_PRIMARY_HOVER_BACKGROUND: COLOR.GOLD_90,
  BUTTON_LIGHT_BACKGROUND: COLOR.GOLD_10,
  BUTTON_LIGHT_COLOR: COLOR.GOLD_70_PRIMARY,
  BUTTON_LIGHT_BORDER_COLOR: COLOR.GOLD_70_PRIMARY,
  BUTTON_LIGHT_HOVER_BACKGROUND: COLOR.GOLD_70_PRIMARY,
  BUTTON_LIGHT_HOVER_COLOR: COLOR.GOLD_10,
  BUTTON_SECONDARY_BACKGROUND: COLOR.BLUE_60_PRIMARY,
  BUTTON_TERTIARY_BACKGROUND: COLOR.PURPLE_60_PRIMARY,
  BUTTON_LINK_COLOR: COLOR.BLUE_90,
  BUTTON_LINK_HOVER_COLOR: COLOR.BLUE_100,
  BUTTON_LINK_ICON_COLOR: COLOR.GOLD_70_PRIMARY,
  BUTTON_LINK_ICON_HOVER_COLOR: COLOR.GOLD_90,
  TEXT_BLOCK_BACKGROUND_SECONDARY: COLOR.GOLD_10,
  TEXT_BLOCK_BACKGROUND_TERTIARY: COLOR.BLUE_60_PRIMARY,
  TEXT_BLOCK_TITLE_COLOR_PRIMARY: COLOR.GOLD_70_PRIMARY,
  TEXT_BLOCK_TITLE_COLOR_TERTIARY: COLOR.GOLD_70_PRIMARY,
  CAROUSEL_DOT_COLOR_INACTIVE: COLOR.GOLD_30,
  CAROUSEL_DOT_COLOR_ACTIVE: COLOR.GOLD_70_PRIMARY,
  BLOCK_BACKGROUND_SECONDARY: COLOR.GOLD_10,
  NEWS_CARD_CATEGORY_COLOR: COLOR.BLUE_90,
  FOOTER_BACKGROUND: COLOR.BLUE_80,
  ACCORDION_TITLE_COLOR: COLOR.GOLD_70_PRIMARY,
  ACCORDION_ICON_COLOR: COLOR.GOLD_70_PRIMARY,
  USP_TITLE_COLOR: COLOR.GOLD_70_PRIMARY,
  USP_ICON_COLOR: COLOR.GOLD_70_PRIMARY,
  USP_ICON_BACKGROUND_COLOR: 'transparant',
  USP_ICON_BORDER_COLOR: COLOR.GOLD_70_PRIMARY,
  CORNER_COLOR_PRIMARY: COLOR.GOLD_70_PRIMARY,
  CORNER_COLOR_SECONDARY: COLOR.BLUE_60_PRIMARY,
  CORNER_COLOR_TERTIARY: COLOR.PURPLE_60_PRIMARY,
  INPUT_FOCUS_COLOR: COLOR.GOLD_70_PRIMARY,
  BLOCK_TITLE_WEIGHT: '800'
}

export type TComponentName = keyof typeof COMPONENTS
