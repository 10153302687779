import styled, { css } from 'styled-components'
import { Icon } from '@hc/shared/components/icon'
import { Link } from '@hc/shared/components/link'
import { HeadingTypes } from '@hc/shared/types/typography'
import { LanguageSwitchHeader } from '@hc/shared/components/language-switch-header'

interface Props {
  isActive?: boolean
  isFixed?: boolean
}

export const StyledHeader = styled.header<Props>`
  position: absolute;
  width: 100%;
  top: 0;
  z-index: ${({ theme }) => theme.Z_INDEX.PAGE_HEADER};

  ${({ isFixed }) =>
    isFixed &&
    css`
      position: fixed;
    `};
`

export const StyledHeaderInner = styled.div<Props>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 6.4rem;
  margin-top: 0.85rem;
  margin-left: -2.4rem;
  margin-right: -2.4rem;
  padding: 0 1.6rem;

  ${({ isFixed }) =>
    isFixed &&
    css`
      background-color: ${({ theme }) => theme.COLOR.NEUTRAL_10}eb;
      border-radius: ${({ theme }) => theme.BORDER_RADIUS.S}rem;
    `};

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${({ theme }) => theme.COLOR.NEUTRAL_10};
      border-radius: ${({ theme }) => theme.BORDER_RADIUS.L}rem
        ${({ theme }) => theme.BORDER_RADIUS.L}rem 0 0;

      @media screen and (min-width: ${({ theme }) =>
          theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
        border-radius: ${({ theme }) => theme.BORDER_RADIUS.S}rem
          ${({ theme }) => theme.BORDER_RADIUS.S}rem 0 0;
      }
    `};

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    justify-content: flex-start;
    margin-left: 18rem;
    width: calc(100% - 18rem);
    justify-content: space-between;
  }
`

export const StyledLanguageSwitchHeader = styled(LanguageSwitchHeader)`
  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    margin-right: 1rem;
  }
`

export const StyledLogoLink = styled(Link)`
  @media screen and (max-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
    position: absolute;
    left: 50%;
    top: calc(50% + 0.8rem);
    transform: translate(-50%, -50%);
  }
`

export const StyledLogo = styled(Icon)<Props>`
  fill: ${({ theme }) => theme.COLOR.NEUTRAL_10};
  height: 3.2rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    position: absolute;
    left: 1.5rem;
    top: 1rem;
    height: 4.7em;
    width: 11em;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      @media screen and (max-width: ${({ theme }) =>
          theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
        fill: ${({ theme }) => theme.COLOR.NEUTRAL_100};
      }
      @media screen and (min-width: ${({ theme }) =>
          theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
        fill: ${({ theme }) => theme.COLOR.LIGHT_BLUE};
        height: 4em;
        width: 9em;
      }
    `};
`

interface NavButtonProps {
  isActive: boolean
}

export const NavButton = styled.button<NavButtonProps>`
  margin-right: 2.4rem;
  border: none;
  background: transparent;
  cursor: pointer;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    display: none;
  }
`

export const NavButtonIcon = styled(Icon)<Props>`
  color: ${({ theme }) => theme.COLOR.NEUTRAL_10};
  height: 3.2rem;
  padding: 1rem;

  svg {
    width: 14px;
    height: 12px;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      fill: ${({ theme }) => theme.COLOR.BLUE_90};
      color: ${({ theme }) => theme.COLOR.BLUE_90};
    `};
`

export const NavHeader = styled.span`
  ${({ theme }) => theme.TYPOGRAPHY[HeadingTypes.DISPLAY_XXS]};
  position: absolute;
  width: fit-content;
  left: 0;
  right: 0;
  margin: 0 auto;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    display: none;
  }
`

export const StyledOverlay = styled.div<Props>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background-color: ${({ theme }) => theme.COLOR.BLUE_90};
  opacity: 0;
  transition: opacity 0.2s
    ${({ theme }) => theme.TRANSITION.TRANSITION_EASE_IN_OUT};
  z-index: ${({ theme }) => theme.Z_INDEX.OVERLAY};

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    background-color: transparent;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      bottom: 0;
      opacity: 0.4;
    `};
`
