/* eslint-disable @typescript-eslint/no-unused-vars */
import { IHrefLink } from '../../utils/types'

import { A } from './Link.styles'

export interface ILinkProps {
  link: IHrefLink | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

export const Link: React.FC<ILinkProps> = ({ link, ...props }) => {
  const { isExternal = false, openInNewWindow, href = '' } = link || {}
  const target = openInNewWindow ? '_blank' : ''

  // Filter out some keys that aren't valid props
  const {
    isActiveIndex,
    isActive,
    isFixed,
    isSquare,
    isSmall,
    isReversed,
    isLarge,
    isStretched,
    isAlternative,
    ...filteredProps
  } = props

  return openInNewWindow || isExternal ? (
    <A href={href} as="a" target={target} rel="noreferrer" {...filteredProps} />
  ) : (
    <A {...filteredProps} to={href} />
  )
}
