import { useEffect } from 'react'
import { apiPlugin, storyblokInit } from 'gatsby-source-storyblok'
import { isSBPreviewMode } from '@hc/shared/domains/storyblok/utils/storyblok'
import mapFooter from '@hc/shared/domains/storyblok/mappers/map-footer'
import mapHeader from '@hc/shared/domains/storyblok/mappers/map-header'
import { Footer } from '@hc/shared/components/footer'
import useLanguageSwitch from '@hc/shared/hooks/use-language-switch'

import { Header } from '@/components/header'
import { useGlobal } from '@/domains/storyblok/hooks'
import { useHCTranslation } from '@/hooks/use-hc-translation'
import { StyledMain, StyledContainer } from '@/layouts/index.style'

/**
 * By default only initialize storyblok without apiPlugin / accesstoken.
 * We don't want to expose the token on the client side.
 *
 * Only load in the apiPlugin in Storyblok edit mode,
 * so that we can fetch the "draft" content.
 */
const isPreviewMode = isSBPreviewMode()
const previewAccessToken =
  (typeof localStorage !== 'undefined' &&
    localStorage.getItem('hc-preview-access-token')) ||
  undefined
const accessToken = isPreviewMode ? previewAccessToken : undefined
const use = isPreviewMode && accessToken ? [apiPlugin] : undefined

storyblokInit({
  bridge: false,
  accessToken,
  use
})

interface IAppLayoutProps {
  children?: React.ReactNode
}

export const AppLayout: React.FC<IAppLayoutProps> = ({ children }) => {
  const { languageRedirect } = useLanguageSwitch()
  const { currentLanguage } = useHCTranslation()
  const data = useGlobal()

  useEffect(() => {
    if (currentLanguage) {
      languageRedirect(currentLanguage)
    }
  }, [currentLanguage])

  const story = data.storyblokEntry
  if (!story || !story.content) {
    return null
  }

  const storyContent = JSON.parse(story.content)

  return (
    <StyledContainer>
      <Header {...mapHeader(storyContent.header[0])} />
      <StyledMain>{children}</StyledMain>
      <Footer {...mapFooter(storyContent.footer[0])} />
    </StyledContainer>
  )
}
