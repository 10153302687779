import { DefaultTheme } from 'styled-components'
import { SHARED_THEME } from '@hc/shared/theme'

import { COLOR } from './basic/color'
import { FONT } from './basic/fonts'
import { COMPONENTS } from './components'
import { SHADOW } from './basic/shadow'
import { ICONS } from './basic/icons'

export const THEME: DefaultTheme = {
  ...SHARED_THEME,
  COLOR: {
    ...SHARED_THEME.COLOR,
    ...COLOR
  },
  FONT,
  SHADOW: {
    ...SHARED_THEME.COLOR,
    ...SHADOW
  },
  ICONS: {
    ...SHARED_THEME.ICONS,
    ...ICONS
  },
  COMPONENTS
}
