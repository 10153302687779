import { useState } from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import useLanguageSwitch from '../../hooks/use-language-switch'
import {
  SUPPORTED_LANGUAGES,
  getFullTranslatedStringFromLocale,
  getCurrentLanguagePath,
  getPathWithoutLanguage,
  stringToLanguageUpper
} from '../../i18n/locales'
import { getUrlParamsString } from '../../utils/url'
import { IconNames } from '../icon'
import { COLOR } from '../../theme/basic/color'

import {
  StyledLanguageSwitch,
  StyledDropdownTrigger,
  StyledDropdown,
  StyledDropdownItem,
  StyledDropdownLink,
  StyledDropdownActive,
  StyledCheckIcon,
  StyledChevronIcon
} from './LanguageSwitchHeader.styles'

interface IPropsLanguageSwitch {
  isFixed?: boolean
  className?: string
}

export const LanguageSwitchHeader: React.FC<IPropsLanguageSwitch> = ({
  isFixed,
  className
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const { t, i18n } = useTranslation()
  const { setLanguage } = useLanguageSwitch()
  const { language } = i18n
  const basePath = getPathWithoutLanguage(language)
  const queryString = getUrlParamsString() || ''

  return (
    <StyledLanguageSwitch
      {...{ className }}
      isFixed={isFixed}
      onMouseOver={() => {
        setIsDropdownOpen(true)
      }}
      onMouseOut={() => {
        setIsDropdownOpen(false)
      }}
    >
      <StyledDropdownTrigger
        isFixed={isFixed}
        aria-label={t('languageSwitch.openLanguageSwitchButton')}
      >
        {stringToLanguageUpper(language)}
        <StyledChevronIcon
          color={isFixed ? COLOR.BLUE_90 : COLOR.NEUTRAL_10}
          size="1rem"
          name={IconNames.CARET_DOWN}
        ></StyledChevronIcon>
      </StyledDropdownTrigger>

      {isDropdownOpen && (
        <StyledDropdown isFixed={isFixed}>
          {SUPPORTED_LANGUAGES.map(lang => {
            const fullLanguage = getFullTranslatedStringFromLocale(lang)

            if (fullLanguage) {
              const langPath = getCurrentLanguagePath(lang)
              return (
                <StyledDropdownItem
                  activeItem={lang === language}
                  key={`lang-dropdown-${lang}`}
                >
                  {lang !== language && (
                    <StyledDropdownLink
                      to={langPath + basePath + queryString}
                      onClick={() => {
                        setLanguage(lang)
                      }}
                    >
                      {t(fullLanguage)}
                    </StyledDropdownLink>
                  )}
                  {lang === language && (
                    <StyledDropdownActive>
                      {t(fullLanguage)}
                      <StyledCheckIcon
                        color={COLOR.NEUTRAL_10}
                        hasCircle={true}
                        name={IconNames.CHECK}
                      />
                    </StyledDropdownActive>
                  )}
                </StyledDropdownItem>
              )
            }
            return null
          })}
        </StyledDropdown>
      )}
    </StyledLanguageSwitch>
  )
}
