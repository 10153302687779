import { useTranslation } from 'gatsby-plugin-react-i18next'

import { getUrlParamsString } from '../../utils/url'
import useLanguageSwitch from '../../hooks/use-language-switch'
import {
  SUPPORTED_LANGUAGES,
  getPathWithoutLanguage,
  getCurrentLanguagePath,
  stringToLanguageUpper
} from '../../i18n/locales'

import {
  StyledLangSwitch,
  StyledLangSwitchItem,
  StyledLink
} from './LanguageSwitch.styles'

import { LanguageSwitchVariant } from './'

interface IPropsLanguageSwitch {
  isFixed?: boolean
  variant?: LanguageSwitchVariant
}

const isSolidSeparator = (activeIndex: number, itemIndex: number) => {
  return activeIndex === itemIndex || activeIndex - itemIndex === 1
}

export const LanguageSwitch: React.FC<IPropsLanguageSwitch> = ({
  isFixed,
  variant = LanguageSwitchVariant.DEFAULT
}) => {
  const { i18n } = useTranslation()
  const { language } = i18n

  const basePath = getPathWithoutLanguage(language)
  const queryString = getUrlParamsString() || ''
  const indexActiveLang = SUPPORTED_LANGUAGES.indexOf(language)
  const { setLanguage } = useLanguageSwitch()

  return (
    <StyledLangSwitch variant={variant}>
      {SUPPORTED_LANGUAGES.map((lang: string, index: number) => {
        const langPath = getCurrentLanguagePath(lang)

        return (
          <StyledLangSwitchItem
            key={`lang-switch-${lang}`}
            isFixed={isFixed}
            isSolidSeparator={isSolidSeparator(indexActiveLang, index)}
            variant={variant}
          >
            {language !== lang && (
              <StyledLink
                to={langPath + basePath + queryString}
                isFixed={isFixed}
                onClick={() => {
                  setLanguage(lang)
                }}
              >
                {stringToLanguageUpper(lang)}
              </StyledLink>
            )}
            {language === lang && stringToLanguageUpper(lang)}
          </StyledLangSwitchItem>
        )
      })}
    </StyledLangSwitch>
  )
}
