import { GatsbyBrowser } from 'gatsby'

import { SharedGatsbyBrowser } from '../shared/gatsby-browser'

import { THEME } from './src/theme'
import { AppLayout } from './src/layouts'

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = args =>
  SharedGatsbyBrowser.onRouteUpdate(args)

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = args =>
  SharedGatsbyBrowser.createWrapPageElement(args, AppLayout)

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = args =>
  SharedGatsbyBrowser.createWrapRootElement(args, THEME)
