export enum CorporateIconNames {
  ALERT = 'alert',
  ALERT_MINI = 'alert-mini',
  CHEVRON_DOWN = 'chevron-down',
  CHEVRON_LEFT = 'chevron-left',
  CHEVRON_RIGHT = 'chevron-right',
  CHEVRON_THIN = 'chevron-thin',
  CHEVRON_UP = 'chevron-up',
  CROSS = 'cross',
  DOTS = 'dots',
  DOWNLOAD = 'download',
  DOWNLOAD_2 = 'download-2',
  HAMBURGER = 'hamburger',
  HAMBURGER_2 = 'hamburger-2',
  INFO = 'info',
  INFO_MINI = 'info-mini',
  LEGAL_REPRESENTATION = 'legal-representation',
  LINK = 'link',
  LINK_THIN = 'link-thin',
  LOCATION = 'location',
  MAIL = 'mail',
  MAIL_MINI = 'mail-mini',
  MINUS = 'minus',
  NEWS = 'news',
  ONLINE_CASINO = 'online-casino',
  PERCENTAGE_CIRCLE = 'percentage-circle',
  PHONE = 'phone',
  PHONE_MINI = 'phone-mini',
  PLAY = 'play',
  PLUS = 'plus',
  RESULTS = 'results',
  SEARCH = 'search',
  UPLOAD = 'upload',
  UPLOAD_2 = 'upload-2',
  VENUE = 'venue',
  WEB = 'web',
  ONLINE_CASINO_CIRCLE = 'online-casino-circle',
  VENUE_CIRCLE = 'venue-circle',
  QUESTION_BUBBLE_CIRCLE = 'question-bubble-circle',
  SPEECH_BUBBLE_CIRCLE = 'speech-bubble-circle',
  PERSONAL_DETAILS_CIRCLE = 'personal-details-circle',
  NEWS_CIRCLE = 'news-circle'
}
