import { COLOR } from '../basic/color'

export const COMPONENTS = {
  BODY_COLOR: COLOR.DARK_BLUE,
  HEADING_TEXT_TRANSFORM: 'uppercase',
  BUTTON_PRIMARY_BACKGROUND: COLOR.LIGHT_BLUE,
  BUTTON_PRIMARY_COLOR: COLOR.NEUTRAL_10,
  BUTTON_PRIMARY_HOVER_BACKGROUND: COLOR.LIGHT_BLUE_HOVER,
  BUTTON_LIGHT_BACKGROUND: COLOR.NEUTRAL_10,
  BUTTON_LIGHT_COLOR: COLOR.LIGHT_BLUE,
  BUTTON_LIGHT_BORDER_COLOR: COLOR.LIGHT_BLUE,
  BUTTON_LIGHT_HOVER_BACKGROUND: COLOR.LIGHT_BLUE,
  BUTTON_LIGHT_HOVER_COLOR: COLOR.NEUTRAL_10,
  BUTTON_SECONDARY_BACKGROUND: COLOR.GOLD,
  BUTTON_TERTIARY_BACKGROUND: COLOR.PINK,
  BUTTON_LINK_COLOR: COLOR.DARK_BLUE,
  BUTTON_LINK_HOVER_COLOR: COLOR.DARK_BLUE,
  BUTTON_LINK_ICON_COLOR: COLOR.DARK_BLUE,
  BUTTON_LINK_ICON_HOVER_COLOR: COLOR.DARK_BLUE,
  TEXT_BLOCK_BACKGROUND_SECONDARY: COLOR.BACKGROUND,
  INPUT_FOCUS_COLOR: COLOR.LIGHT_BLUE,
  TEXT_BLOCK_BACKGROUND_TERTIARY: COLOR.LIGHT_BLUE,
  TEXT_BLOCK_TITLE_COLOR_PRIMARY: COLOR.DARK_BLUE,
  TEXT_BLOCK_TITLE_COLOR_TERTIARY: COLOR.NEUTRAL_10,
  CAROUSEL_DOT_COLOR_INACTIVE: COLOR.DARK_BLUE_30,
  CAROUSEL_DOT_COLOR_ACTIVE: COLOR.DARK_BLUE,
  BLOCK_BACKGROUND_SECONDARY: COLOR.BACKGROUND,
  NEWS_CARD_CATEGORY_COLOR: COLOR.LIGHT_BLUE,
  FOOTER_BACKGROUND: COLOR.DARK_BLUE,
  ACCORDION_TITLE_COLOR: COLOR.LIGHT_BLUE,
  ACCORDION_ICON_COLOR: COLOR.LIGHT_BLUE,
  USP_TITLE_COLOR: COLOR.DARK_BLUE,
  USP_ICON_COLOR: COLOR.NEUTRAL_10,
  USP_ICON_BACKGROUND_COLOR: COLOR.DARK_BLUE,
  USP_ICON_BORDER_COLOR: COLOR.DARK_BLUE,
  CORNER_COLOR_PRIMARY: COLOR.LIGHT_BLUE,
  CORNER_COLOR_SECONDARY: COLOR.GOLD,
  CORNER_COLOR_TERTIARY: COLOR.PINK,
  BLOCK_TITLE_WEIGHT: '300'
}

export type TComponentName = keyof typeof COMPONENTS
