import styled, { css } from 'styled-components'
import { HeadingTypes } from '@hc/shared/types/typography'
import { Icon } from '@hc/shared/components/icon'
import { LayoutContainer } from '@hc/shared/components/layout'

interface IProps {
  isActive?: boolean
  isFixed?: boolean
  light?: boolean
  isActiveIndex?: boolean
  isCaret?: boolean
  isArrow?: boolean
}

export const StyledContainer = styled(LayoutContainer)`
  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    padding: 0;
    margin: 0;
    height: 43px;
    display: flex;
    align-items: center;
    width: unset;
  }
`

export const StyledNav = styled.nav<IProps>`
  @media screen and (max-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
    position: fixed;
    display: block;
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
    background-color: ${({ theme }) => theme.COLOR.NEUTRAL_10};
    border-radius: 0 0 ${({ theme }) => theme.BORDER_RADIUS.XL}rem
      ${({ theme }) => theme.BORDER_RADIUS.XL}rem;
    overflow: hidden;
    top: 6.4rem;
    left: 0;
    z-index: -1;
    transform: translateY(-100vh);
    transition: 0.2s ${({ theme }) => theme.TRANSITION.TRANSITION_EASE_IN_OUT};
    height: calc(100% - 6.4rem - 1.6rem);
    max-height: 100%;

    ${({ isActive }) =>
      isActive &&
      css`
        transform: translateY(0);
      `};
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    height: 100%;
    margin-left: auto;
    z-index: ${({ theme }) => theme.Z_INDEX.NAVIGATION};
  }
`

export const StyledNavList = styled.ul`
  height: calc(100% - 6rem);

  @media screen and (max-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
    overflow: auto;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    display: flex;
    height: 100%;
  }
`

export const StyledNavItem = styled.li`
  font-family: ${({ theme }) => theme.FONT.PRIMARY};
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    margin-left: 1em;
  }
`

export const StyledNavButton = styled.button<IProps>`
  ${({ theme }) => theme.TYPOGRAPHY[HeadingTypes.DISPLAY_XXS]};
  font-family: ${({ theme }) => theme.FONT.PRIMARY};
  background-color: transparent;
  cursor: pointer;
  color: ${({ theme }) => theme.COLOR.BLUE_90};
  position: relative;
  display: flex;
  align-items: center;

  @media screen and (max-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 2.8rem;
    background-color: ${({ theme }) => theme.COLOR.NEUTRAL_20};
    margin-bottom: 0.6rem;

    svg {
      width: 1.1rem;
    }
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    min-height: 24px;
    color: ${({ theme }) => theme.COLOR.NEUTRAL_10};

    ${({ light }) =>
      light &&
      css`
        font-weight: 300;
        padding-right: 2em;
        border-right: 1px solid ${({ theme }) => theme.COLOR.NEUTRAL_10};
      `};

    ${({ isActive, isFixed }) =>
      (isActive || isFixed) &&
      css`
        color: ${({ theme }) => theme.COLOR.BLUE_90};
        border-color: ${({ theme }) => theme.COLOR.BLUE_90};
      `};
  }
`

export const StyledIcon = styled(Icon)<IProps>`
  color: ${({ theme }) => theme.COLOR.BLUE_10};
  margin-left: 1.2rem;

  @media screen and (max-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG - 1}px) {
    ${({ isCaret }) =>
      isCaret &&
      css`
        display: none;
      `};
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    fill: ${({ theme }) => theme.COLOR.NEUTRAL_10};
    border: none;
    padding: unset;
    height: 1.2rem;
    margin-top: -0.5rem;
    margin-left: 0.8rem;

    ${({ isArrow }) =>
      isArrow &&
      css`
        display: none;
      `};

    ${({ isActive, isFixed }) =>
      (isActive || isFixed) &&
      css`
        fill: ${({ theme }) => theme.COLOR.BLUE_90};
      `};
  }
`

export const LanguageSwitchWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
