import { ReactNode, useEffect, useState } from 'react'
import { useTheme } from 'styled-components'

import { HeadingTypes } from '../../types/typography'
import { IconNames } from '../icon'
import { Heading } from '../typography'
import { SiteUtils } from '../../utils/site'

import {
  StyledAccordion,
  StyledAccordionBody,
  StyledAccordionButton,
  StyledAccordionIcon,
  StyledAccordionContainer
} from './AccordionItem.style'
import type { AccordionVariant } from './AccordionItem.types'

export enum initialAccordionItemStates {
  CLOSED,
  OPEN
}

interface IAccordionItemProps {
  title?: ReactNode
  className?: string
  isActive?: boolean
  variant?: AccordionVariant
  children?: ReactNode
  initialState?: initialAccordionItemStates
  openIcon?: IconNames
  closedIcon?: IconNames
}

export const AccordionItem: React.FC<IAccordionItemProps> = ({
  title,
  isActive = false,
  children,
  variant = 'light',
  className,
  initialState = initialAccordionItemStates.CLOSED,
  openIcon,
  closedIcon
}) => {
  const [isOpen, setOpen] = useState(!isActive ? true : !!initialState)
  const { ICONS } = useTheme()
  const isLandbased = SiteUtils.isLandbased()

  useEffect(() => {
    setOpen(!isActive ? true : !!initialState)
  }, [isActive, initialState])

  const onToggle = () => {
    if (!isActive) return
    setOpen(!isOpen)
  }

  const animation = {
    initial: isOpen ? 'open' : 'collapsed',
    animate: isOpen ? 'open' : 'collapsed',
    variants: {
      open: { opacity: 1, height: 'auto' },
      collapsed: { opacity: 0, height: 0 }
    },
    transition: {
      ease: [0.45, 0, 0.55, 1],
      duration: 0.4
    }
  }

  return (
    <StyledAccordion {...{ className, isActive, variant }}>
      <StyledAccordionButton
        variant={variant}
        type="button"
        onClick={onToggle}
        isActive={isActive}
      >
        {/* @todo This could be changed to always render, but hide it with CSS media queries (instead of useMedia) */}
        <Heading type={HeadingTypes.DISPLAY_XXS} {...{ variant }}>
          {title}
        </Heading>
        {isActive && (
          <StyledAccordionIcon
            className="accordion-item-icon"
            hasCircle={isLandbased}
            variant={variant}
            name={
              !isOpen
                ? closedIcon || ICONS.ACCORDION_OPEN
                : openIcon || ICONS.ACCORDION_CLOSE
            }
          />
        )}
      </StyledAccordionButton>
      <StyledAccordionBody {...animation}>
        <StyledAccordionContainer {...{ variant }}>
          {children}
        </StyledAccordionContainer>
      </StyledAccordionBody>
    </StyledAccordion>
  )
}
