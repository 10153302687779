export enum HeadingTypes {
  DISPLAY_XXL = 'DISPLAY_XXL',
  DISPLAY_XL = 'DISPLAY_XL',
  DISPLAY_L = 'DISPLAY_L',
  DISPLAY_M = 'DISPLAY_M',
  DISPLAY_S = 'DISPLAY_S',
  DISPLAY_XS = 'DISPLAY_XS',
  DISPLAY_XXS = 'DISPLAY_XXS',
}

export enum ParagraphTypes {
  TEXT_L = 'TEXT_L',
  TEXT_M = 'TEXT_M',
  TEXT_S = 'TEXT_S',
  TEXT_XS = 'TEXT_XS',
}
