import { rewriteSlug } from '../../../utils/url'
import { IHrefLink } from '../../../utils/types'
import { LinkField } from '../models/fields/types'

const mapLink = (link: LinkField): IHrefLink | null => {
  if (link === undefined) {
    return null
  }

  const isExternal = link.linktype !== 'story'

  let href
  if (link.linktype === 'url' || link.linktype === 'asset') {
    href = link.url
  } else if (link.linktype === 'story') {
    href = link.story?.url
    if (href && href !== '/') {
      href = `/${link.story?.url}`
      href += link.story?.url?.slice(-1) !== '/' ? '/' : ''
    }
  }

  href = `${rewriteSlug(href || '', process.env.GATSBY_SITE)}`
  if (!isExternal && !href.startsWith('/')) {
    href = `/${href}`
  }

  return {
    href,
    isExternal,
    openInNewWindow: isExternal
  }
}

export default mapLink
