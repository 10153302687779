import { IHrefLink } from '../../../utils/types'
import { FetchedCallToAction } from '../models/components/types'

import mapLink from './map-link'

export interface ICallToAction {
  _uid: string
  label?: string
  link?: IHrefLink | null
}

const mapCallToAction = ({
  link,
  ...props
}: FetchedCallToAction): ICallToAction => {
  return {
    link: link && mapLink(link),
    ...props
  }
}

export default mapCallToAction
