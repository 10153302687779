import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import { ParagraphTypes } from '../../types/typography'

import { LanguageSwitchVariant } from './'
interface IPropsSwitch {
  isFixed?: boolean
}

interface IPropsSwitchItem extends IPropsSwitch {
  isSolidSeparator: boolean
  variant?: LanguageSwitchVariant
}

interface IPropsSwitchList {
  variant?: LanguageSwitchVariant
}

export const StyledLangSwitch = styled.ul<IPropsSwitchList>`
  position: relative;
  display: flex;
  padding: 0 3.2rem;
  ${({ variant }) =>
    variant === LanguageSwitchVariant.FOOTER &&
    css`
      padding: 0;
    `}
  @media screen and
    (min-width: ${({ theme }) => theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    justify-content: center;
    padding: 0 1.2rem 0 0.4rem;
    margin-left: 2rem;

    ${({ variant }) =>
      variant === LanguageSwitchVariant.FOOTER &&
      css`
        margin-left: 0;
      `}
  }
`

export const StyledLangSwitchItem = styled.li<IPropsSwitchItem>`
  ${({ theme }) => theme.TYPOGRAPHY[ParagraphTypes.TEXT_M]};
  padding: 0 0.8rem;
  position: relative;
  font-weight: 700;
  line-height: 1.3rem;

  &:after {
    content: '';
    position: absolute;
    width: 1px;
    background-color: ${({ theme }) => theme.COLOR.BLUE_30};
    right: 0;
    top: 0;
    height: 100%;

    ${({ isSolidSeparator }) =>
      isSolidSeparator &&
      css`
        background-color: ${({ theme }) => theme.COLOR.BLUE_90};
      `}

    ${({ variant }) =>
      variant === LanguageSwitchVariant.FOOTER &&
      css`
        background-color: ${({ theme }) => theme.COLOR.NEUTRAL_10};
        opacity: 0.5;
      `}
    ${({ variant, isSolidSeparator }) =>
      variant === LanguageSwitchVariant.FOOTER &&
      isSolidSeparator &&
      css`
        opacity: 1;
      `}
  }

  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;

    &:after {
      display: none;
    }
  }

  ${({ variant }) =>
    variant === LanguageSwitchVariant.FOOTER &&
    css`
      color: ${({ theme }) => theme.COLOR.NEUTRAL_10};
    `}

  @media screen and (min-width: ${({ theme }) =>
    theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    display: flex;
    align-items: center;
    ${({ isFixed }) =>
      !isFixed &&
      css`
        color: ${({ theme }) => theme.COLOR.NEUTRAL_10};
        &:after {
          background-color: ${({ theme }) => theme.COLOR.NEUTRAL_10};
          opacity: 0.5;
        }
      `}
    ${({ isFixed, isSolidSeparator }) =>
      !isFixed &&
      isSolidSeparator &&
      css`
        &:after {
          opacity: 1;
        }
      `};
  }
`

export const StyledLink = styled(Link)<IPropsSwitch>`
  color: ${({ theme }) => theme.COLOR.BLUE_30};
  line-height: 1.3rem;
  font-weight: 400;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINT.LG}px) {
    color: ${({ theme }) => theme.COLOR.NEUTRAL_10};
    opacity: 0.5;
    ${({ isFixed }) =>
      isFixed &&
      css`
        opacity: 1;
        color: ${({ theme }) => theme.COLOR.BLUE_30};
      `};
  }
`
