import { IconNames } from './constants'
import { StyledIcon } from './Icon.styles'
import { Icons } from './svg'

export interface IIconProps {
  name: IconNames
  color?: string
  size?: string
  className?: string
  hasCircle?: boolean
}

export const Icon: React.FC<IIconProps> = ({
  name,
  color,
  size = '2.4rem',
  hasCircle,
  className
}) => {
  const SvgIcon = Icons[name]

  if (SvgIcon) {
    return (
      <StyledIcon
        color={color}
        size={size}
        hasCircle={hasCircle}
        className={className}
      >
        <SvgIcon />
      </StyledIcon>
    )
  }

  return null
}
