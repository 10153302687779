import { IconNames } from '@hc/shared/components/icon'
import { ICallToAction } from '@hc/shared/domains/storyblok/mappers/map-call-to-action'

import {
  StyledContainer,
  StyledIcon,
  StyledLink,
  StyledList,
  StyledItem,
  StyledNavSub
} from './NavigationSub.style'

interface IProps {
  items: ICallToAction[] | undefined
  onClick: () => void
  [key: string]: unknown
}

export const NavigationSub = ({
  items,
  onClick,
  ...props
}: IProps): JSX.Element => {
  return (
    <StyledNavSub {...props}>
      <StyledContainer>
        <StyledList>
          {items?.map(item => (
            <StyledItem key={item._uid} onClick={onClick}>
              <StyledLink link={item.link}>
                {item.label}
                {item.link?.isExternal ? (
                  <StyledIcon name={IconNames.LINK} />
                ) : (
                  <StyledIcon name={IconNames.CARET_RIGHT} />
                )}
              </StyledLink>
            </StyledItem>
          ))}
        </StyledList>
      </StyledContainer>
    </StyledNavSub>
  )
}
