import { navigate } from 'gatsby'

import { HCStorage } from '../utils/storage'
import { getUrlParamsString } from '../utils/url'
import {
  getCurrentLanguagePath,
  getPathWithoutLanguage,
  SUPPORTED_LANGUAGES
} from '../i18n/locales'
import { STORAGE_LANG } from '../const'

const useLanguageSwitch = () => {
  const setLanguage = (lang: string) => {
    HCStorage.setItem(STORAGE_LANG, lang)
  }
  const getLanguage = (): string | undefined | null => {
    return HCStorage.getItem(STORAGE_LANG)
  }

  const languageRedirect = (currentLang: string) => {
    if (typeof window === 'undefined') {
      return null
    }
    const storedLanguage = getLanguage()

    if (
      storedLanguage &&
      SUPPORTED_LANGUAGES.includes(storedLanguage) &&
      storedLanguage !== currentLang
    ) {
      const basePath = getPathWithoutLanguage(currentLang)
      const queryString = getUrlParamsString()

      const langPath = getCurrentLanguagePath(storedLanguage)
      navigate(langPath + basePath + queryString)
    }
  }

  return {
    setLanguage,
    getLanguage,
    languageRedirect
  }
}

export default useLanguageSwitch
