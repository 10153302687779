import { IHrefLink } from '../../../utils/types'
import { FetchedDownloadButton } from '../models/components/types'

import mapLink from './map-link'

export interface IDownloadButtonProps {
  _uid: string
  type: string
  link: IHrefLink | null
}

const mapDownloadButton = ({
  link,
  ...props
}: FetchedDownloadButton): IDownloadButtonProps => {
  return {
    link: link && mapLink(link),
    ...props
  }
}

export default mapDownloadButton
