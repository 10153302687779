import { useStaticQuery, graphql } from 'gatsby'

export const useGlobal = () => {
  const data: Queries.GlobalQuery = useStaticQuery(graphql`
    query Global {
      storyblokEntry(full_slug: { eq: "corporate/templates/global" }) {
        content
        name
        full_slug
        uuid
        id
        internalId
      }
    }
  `)

  return data
}
