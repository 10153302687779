import { CorporateIconNames } from '../constants'
import { SvgComponent } from '../Icon.types'

type IconType = {
  [i in CorporateIconNames]: SvgComponent
}

/* eslint-disable @typescript-eslint/no-var-requires */
export const Icons: IconType = {
  [CorporateIconNames.ALERT]: require(`./${CorporateIconNames.ALERT}.svg`)
    .default,
  [CorporateIconNames.ALERT_MINI]:
    require(`./${CorporateIconNames.ALERT_MINI}.svg`).default,
  [CorporateIconNames.CHEVRON_DOWN]:
    require(`./${CorporateIconNames.CHEVRON_DOWN}.svg`).default,
  [CorporateIconNames.CHEVRON_LEFT]:
    require(`./${CorporateIconNames.CHEVRON_LEFT}.svg`).default,
  [CorporateIconNames.CHEVRON_RIGHT]:
    require(`./${CorporateIconNames.CHEVRON_RIGHT}.svg`).default,
  [CorporateIconNames.CHEVRON_UP]:
    require(`./${CorporateIconNames.CHEVRON_UP}.svg`).default,
  [CorporateIconNames.CHEVRON_THIN]:
    require(`./${CorporateIconNames.CHEVRON_THIN}.svg`).default,
  [CorporateIconNames.CROSS]: require(`./${CorporateIconNames.CROSS}.svg`)
    .default,
  [CorporateIconNames.DOTS]: require(`./${CorporateIconNames.DOTS}.svg`)
    .default,
  [CorporateIconNames.DOWNLOAD]: require(`./${CorporateIconNames.DOWNLOAD}.svg`)
    .default,
  [CorporateIconNames.DOWNLOAD_2]:
    require(`./${CorporateIconNames.DOWNLOAD_2}.svg`).default,
  [CorporateIconNames.HAMBURGER]:
    require(`./${CorporateIconNames.HAMBURGER}.svg`).default,
  [CorporateIconNames.HAMBURGER_2]:
    require(`./${CorporateIconNames.HAMBURGER_2}.svg`).default,
  [CorporateIconNames.INFO]: require(`./${CorporateIconNames.INFO}.svg`)
    .default,
  [CorporateIconNames.INFO_MINI]:
    require(`./${CorporateIconNames.INFO_MINI}.svg`).default,
  [CorporateIconNames.LEGAL_REPRESENTATION]:
    require(`./${CorporateIconNames.LEGAL_REPRESENTATION}.svg`).default,
  [CorporateIconNames.LINK]: require(`./${CorporateIconNames.LINK}.svg`)
    .default,
  [CorporateIconNames.LINK_THIN]:
    require(`./${CorporateIconNames.LINK_THIN}.svg`).default,
  [CorporateIconNames.LOCATION]: require(`./${CorporateIconNames.LOCATION}.svg`)
    .default,
  [CorporateIconNames.MAIL]: require(`./${CorporateIconNames.MAIL}.svg`)
    .default,
  [CorporateIconNames.MAIL_MINI]:
    require(`./${CorporateIconNames.MAIL_MINI}.svg`).default,
  [CorporateIconNames.MINUS]: require(`./${CorporateIconNames.MINUS}.svg`)
    .default,
  [CorporateIconNames.NEWS]: require(`./${CorporateIconNames.NEWS}.svg`)
    .default,
  [CorporateIconNames.ONLINE_CASINO]:
    require(`./${CorporateIconNames.ONLINE_CASINO}.svg`).default,
  [CorporateIconNames.PERCENTAGE_CIRCLE]:
    require(`./${CorporateIconNames.PERCENTAGE_CIRCLE}.svg`).default,
  [CorporateIconNames.PHONE]: require(`./${CorporateIconNames.PHONE}.svg`)
    .default,
  [CorporateIconNames.PHONE_MINI]:
    require(`./${CorporateIconNames.PHONE_MINI}.svg`).default,
  [CorporateIconNames.PLUS]: require(`./${CorporateIconNames.PLUS}.svg`)
    .default,
  [CorporateIconNames.PLAY]: require(`./${CorporateIconNames.PLAY}.svg`)
    .default,
  [CorporateIconNames.RESULTS]: require(`./${CorporateIconNames.RESULTS}.svg`)
    .default,
  [CorporateIconNames.SEARCH]: require(`./${CorporateIconNames.SEARCH}.svg`)
    .default,
  [CorporateIconNames.UPLOAD]: require(`./${CorporateIconNames.UPLOAD}.svg`)
    .default,
  [CorporateIconNames.UPLOAD_2]: require(`./${CorporateIconNames.UPLOAD_2}.svg`)
    .default,
  [CorporateIconNames.VENUE]: require(`./${CorporateIconNames.VENUE}.svg`)
    .default,
  [CorporateIconNames.WEB]: require(`./${CorporateIconNames.WEB}.svg`).default,
  [CorporateIconNames.ONLINE_CASINO_CIRCLE]:
    require(`./${CorporateIconNames.ONLINE_CASINO_CIRCLE}.svg`).default,
  [CorporateIconNames.VENUE_CIRCLE]:
    require(`./${CorporateIconNames.VENUE_CIRCLE}.svg`).default,
  [CorporateIconNames.QUESTION_BUBBLE_CIRCLE]:
    require(`./${CorporateIconNames.QUESTION_BUBBLE_CIRCLE}.svg`).default,
  [CorporateIconNames.SPEECH_BUBBLE_CIRCLE]:
    require(`./${CorporateIconNames.SPEECH_BUBBLE_CIRCLE}.svg`).default,
  [CorporateIconNames.PERSONAL_DETAILS_CIRCLE]:
    require(`./${CorporateIconNames.PERSONAL_DETAILS_CIRCLE}.svg`).default,
  [CorporateIconNames.NEWS_CIRCLE]:
    require(`./${CorporateIconNames.NEWS_CIRCLE}.svg`).default
}
