import { CornerPosition, CornerVariant } from './Corner.types'
import { StyledCorner } from './Corner.style'

interface ICornerProps {
  className?: string
  variant?: CornerVariant
  position?: CornerPosition
  isSmall?: boolean
}

export const Corner: React.FC<ICornerProps> = ({
  variant = 'primary',
  position = 'left-top',
  isSmall,
  className
}) => {
  return <StyledCorner {...{ className, variant, position, isSmall }} />
}
