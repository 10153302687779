import styled from 'styled-components'

export const StyledIcon = styled.span`
  display: inline-block;
  height: 2.4rem;
  fill: ${({ theme }) => theme.COLOR.BLUE_90};
  line-height: 1;

  svg {
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
`
